.@{splitter-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-dot {
    &-spin {
      .@{splitter-prefix-cls}-rtl & {
        transform: rotate(-45deg);
        animation-name: antRotateRtl;
      }
    }
  }
}

@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
