.empty-investor {
 .ant-empty-description {
  font-size: 20px;
  font-weight: bold;
  color: $white;
  }
  .ant-empty-image {
    margin-bottom: -8px;
  }
}
