@import 'variable';

.mobile-tabs-open {
  .ant-tabs-nav {
    width: 30px;
    max-width: 30px;
    position: sticky !important;
    top: 0;
    z-index: 9;
    background-color: white;
    transform: translateX(-18px);
    .ant-tabs-tab {
      padding-left: 20px;
    }
  }
}
.ant-tabs {
  margin-right: 0px !important;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding: 0px !important;
}

@media screen and (max-width: 800px) {
  .mobile-tabset {
    max-width: 100dvw;

    .ant-tabs-nav {
      height: fit-content;
      max-height: 40%;
      position: fixed;
      right: 10px;
      bottom: 30%;
      z-index: 9;
      background-color: white;
    }

    .ant-tabs-tab-active {
      background-color: $blue-dark !important;
      button[role='tab'] {
        color: white !important;
      }
      color: white !important;
    }
  }

  .mobile-tabs-collapsed {
    .ant-tabs-nav {
      width: 50px;
      min-width: 50px;
      .ant-tabs-tab i {
        font-size: large;
        line-height: normal;
        transform: translateX(-7px);
      }
    }
  }

  .mobile-tabs-button {
    width: 50px;
    position: fixed;
    transform: translateY(100%);
    right: 10px;
    bottom: 30%;
    z-index: 9;
  }

  .mobile-tabs-hide-button {
    width: 50px;
    position: fixed;
    transform: translateY(200%);
    right: 10px;
    z-index: 9;
  }

  .mobile-tabset-open {
    background-color: $main-app-color;
    color: white;
    border-radius: 5px 0 0 5px;
    width: 25px;
    height: 50px;
    display: grid;
    place-content: center;
    position: fixed;
    right: 60px;
    bottom: 40%;
    z-index: 6;
  }

  .nav-hidden {
    .ant-tabs-nav {
      display: none;
    }
  }

  .tabset-hidden {
    right: 0;
  }
}
