@media screen and (min-width: $minComputerWidth) {
  .filter-container {
    display: grid;
    grid-template-columns: 70% 30%;

    button {
      margin-left: 10px;
      margin-top: 10px;
    }
  }

  .multiple-rows {
    margin-top: -8px;
  }

  .filter-input-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 20px;

    .last-filter-select {
      margin-top: -30px;
    }
  }

  .filter-action-container {
    padding-top: 22px;
    margin-left: auto;
  }
}
@media screen and (max-width: $minComputerWidth) {
  .filter-container {
    display: grid;
    grid-template-columns: 1fr;

    button {
      margin-left: 10px;
      margin-top: 10px;
    }
  }

  .filter-input-container {
    display: grid;
    grid-template-columns: 1fr;
    .ant-form-item-control-input-content {
      margin-bottom: 15px;
    }

    .ant-form-item {
      margin-bottom: 0px;
    }
  }

  .filter-action-container {
    padding-top: 22px;
  }
}
