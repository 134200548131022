@import 'variable';

html,
body {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  height: 100%;
}

table a {
  color: $link-blue;
}

.example-spacer {
  flex: 1 1 auto;
}

html,
body,
.my-content {
  margin: 0;
  width: 100%;
  height: 100%;
}

.my-content {
  background: $background-color;
}

.fixedMenu {
  top: 0;
}
