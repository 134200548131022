.action-buttons {
  margin-top: 40px;
  text-align: right;
}

.save-button {
  margin-right: 10px;
}

.main-list-action-icon {
  margin: 0 10px;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
}

.delete-payment-btn {
  align-self: flex-end;
  margin-left: 10px;
}

.add-payment-btn {
  margin-top: 20px;
}

.search-button {
  margin: 5px 0;
}

.search-container.ant-dropdown-menu-item {
  padding: 0;
}

.status-select {
  width: 200px !important;
}

.inside-card-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.generate-email-button {
  width: 100%;
  text-align: right;
  padding: 0 15px 20px 15px;
}
