@import 'variable';

.breadcrump {
  font-size: 12px;
  color: rgb(162, 162, 162) !important;

  &-component {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }

  &-list {
    display: inline-block;

    li {
      display: inline-block;
      padding-right: 20px;
      position: relative;

      &:first-of-type {
        width: 50px;
      }

      a {
        color: rgb(162, 162, 162) !important;
        font-size: 12px;
      }

      .main-icon {
        top: -12px;
        left: 21px;
        position: absolute;
      }
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  .my-content {
    margin-left: 50px !important;
  }

  .list-header--title {
    font-size: 12px;
  }

  .breadcrump-list li {
    a {
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
  .my-content {
    margin-left: 50px !important;
  }

  .list-header--title {
    font-size: 14px;
  }

  .breadcrump-list li {
    a {
      font-size: 11px;
    }
  }
}

@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .my-content {
    margin-left: 50px !important;
  }

  .list-header--title {
    font-size: 15px;
  }

  .breadcrump-list li {
    a {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 5000px) {
  .my-content {
    margin-left: 50px !important;
  }

  .list-header--title {
    font-size: 15px;
  }

  .breadcrump-list li {
    a {
      font-size: 13px;
    }
  }
}

.breadcrump-list li a {
  color: $white !important;
}

.breadcrump button {
  margin-left: 10px;
}

.vertical-center {
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }
}
