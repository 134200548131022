@import 'variable';

.simple-notification .sn-title {
  font-size: 17px !important;
}
.simple-notification .sn-content {
  font-size: 13px !important;
}

.toast-container .toast {
  padding: 20px 15px 20px 50px !important;
}

.toast-success {
  background-color: $green-background !important;
}
.toast-error {
  background-color: $red-background !important;
}
.toast-warning {
  background-color: $yellow-background !important;
}
