html,
body {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  margin: 0;
  height: 100%;
}

table a {
  color: #0da0fe;
}

.example-spacer {
  flex: 1 1 auto;
}

html,
body,
.my-content {
  margin: 0;
  width: 100%;
  height: 100%;
}

.my-content {
  background: #f5f5f5;
}

.fixedMenu {
  top: 0;
}

.breadcrump {
  font-size: 12px;
  color: rgb(162, 162, 162) !important;
}
.breadcrump-component {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
.breadcrump-list {
  display: inline-block;
}
.breadcrump-list li {
  display: inline-block;
  padding-right: 20px;
  position: relative;
}
.breadcrump-list li:first-of-type {
  width: 50px;
}
.breadcrump-list li a {
  color: rgb(162, 162, 162) !important;
  font-size: 12px;
}
.breadcrump-list li .main-icon {
  top: -12px;
  left: 21px;
  position: absolute;
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
  .my-content {
    margin-left: 50px !important;
  }
  .list-header--title {
    font-size: 12px;
  }
  .breadcrump-list li a {
    font-size: 10px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1599px) {
  .my-content {
    margin-left: 50px !important;
  }
  .list-header--title {
    font-size: 14px;
  }
  .breadcrump-list li a {
    font-size: 11px;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .my-content {
    margin-left: 50px !important;
  }
  .list-header--title {
    font-size: 15px;
  }
  .breadcrump-list li a {
    font-size: 12px;
  }
}
@media screen and (min-width: 1920px) and (max-width: 5000px) {
  .my-content {
    margin-left: 50px !important;
  }
  .list-header--title {
    font-size: 15px;
  }
  .breadcrump-list li a {
    font-size: 13px;
  }
}
.breadcrump-list li a {
  color: #ffffff !important;
}

.breadcrump button {
  margin-left: 10px;
}

.vertical-center {
  display: flex;
  align-items: center;
}
.vertical-center i {
  margin-right: 10px;
}

.action-buttons {
  margin-top: 40px;
  text-align: right;
}

.save-button {
  margin-right: 10px;
}

.main-list-action-icon {
  margin: 0 10px;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
}

.delete-payment-btn {
  align-self: flex-end;
  margin-left: 10px;
}

.add-payment-btn {
  margin-top: 20px;
}

.search-button {
  margin: 5px 0;
}

.search-container.ant-dropdown-menu-item {
  padding: 0;
}

.status-select {
  width: 200px !important;
}

.inside-card-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.generate-email-button {
  width: 100%;
  text-align: right;
  padding: 0 15px 20px 15px;
}

.ant-card {
  border-radius: 20px;
  margin: 16px 0;
}

.edit-card {
  margin: 0;
}

.nz-card-no-margins {
  margin: 0;
}

.edit-card-w-margin-bottom {
  margin-bottom: 16px;
}

.ant-card-head {
  display: flex;
  align-items: center;
  min-height: 80px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: repeating-radial-gradient(circle at 100% 0, transparent 0, #1f2c4f 50px), linear-gradient(0deg, #234666 0%, #1f2c4f 100%);
}
.ant-card-head-title {
  white-space: pre-line;
}

.ant-card-head-wrapper {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}

.margin {
  margin: 0 10px;
}
.margin ant-row {
  flex-flow: none !important;
}
.margin nz-card {
  width: 80%;
  flex-flow: none !important;
}

.card-blue {
  background: #a7b5dd;
}
.card-blue .nz-list-text {
  color: #ffffff;
}
.card-darkblue {
  background: #1f2c4f;
}
.card-darkblue .nz-list-text {
  color: #ffffff;
}
.card-darkblue td {
  font-size: 13px;
}
.card-green {
  background: #c2ecc0;
}
.card-green .nz-list-text {
  color: #ffffff;
}
.card-green td {
  font-size: 13px;
}
.card-yellow {
  background: #e0d31c;
}
.card-yellow .nz-list-text {
  color: #ffffff;
}
.card-yellow td {
  font-size: 13px;
}

.add-image-container {
  min-height: 300px;
}

.file-input {
  display: none;
}

.button-container {
  margin-top: 16px;
}

.photo-file {
  max-width: 200px;
  max-height: 200px;
}

.structure-card {
  margin-right: 16px;
}

nz-carousel .slick-dots {
  padding-bottom: 25px;
}

.payments-container {
  margin-top: 40px;
  height: auto;
}

.input-container {
  margin-left: 10px;
}

.search-box {
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.multi-select {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.page-container {
  padding: 1px 16px;
}

.document-item__add {
  display: flex;
  padding: 0px 15px;
}

.ant-input[disabled] {
  color: #525252;
}

.ant-input[disabled]::placeholder {
  color: #525252;
}

.ant-picker-input > input[disabled] {
  color: #525252;
}

.ant-picker-input > input[disabled]::placeholder {
  color: #525252;
}

.ant-input-number-disabled .ant-input-number-input {
  color: #525252;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #525252;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selection-placeholder {
  color: #525252;
}

.ant-checkbox-disabled + span {
  color: #525252;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #525252;
}

@media screen and (min-width: 801px) {
  .small-screen-only {
    display: none;
  }
}
.document-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.document-item h3 {
  font-size: 16px;
  font-weight: 600;
  white-space: initial !important;
  margin-bottom: 0;
  line-height: 1.5;
}
.document-item h3:first-letter {
  text-transform: uppercase;
}
.document-item__add {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.document-item__download a {
  align-items: center;
}
.document-item:hover .document-item__add {
  opacity: 1;
}

.document_add_for_transfer {
  width: 400px;
}

.insurance-download {
  height: 38px !important;
  width: 120px;
  font-size: 15px !important;
}

.document-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

@media screen and (min-width: 801px) {
  .document-item {
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    text-align: auto;
    align-items: auto;
  }
}
.empty-investor .ant-empty-description {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.empty-investor .ant-empty-image {
  margin-bottom: -8px;
}

.file-container {
  padding: 8px;
  position: relative;
  margin-bottom: 15px;
}
.file-container:first-child {
  padding-left: 0;
}
.file-thumbnail {
  background-size: cover;
  background-position: center center;
  height: 200px;
  width: 100%;
  border-radius: 5px;
  position: relative;
}
.file-thumbnail a {
  display: block;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 110px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  padding-top: 90px;
  font-size: 16px;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
}
.file-thumbnail a:hover {
  opacity: 1;
}
.file-date {
  color: #ccc;
  float: right;
  font-size: 9px;
  display: block;
}
.file-delete {
  position: absolute;
  top: 0;
  right: 0;
}
.file-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.3);
}

.incoming-post.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  background-color: #6ed16d;
  border-color: #6ed16d;
}

.post-label-buttons.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #36a3f7;
  border-color: #36a3f7;
}

.footer-bottom {
  width: 100%;
  display: flex;
  position: sticky;
  padding: 3px;
  bottom: 0;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  justify-content: flex-end;
  padding-right: 20px;
  z-index: 100;
  background: white;
}

.formContent {
  display: contents;
}

.content-form-columns {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding: 0px 20px 20px;
}

.content-form-3-columns {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  padding: 0px 20px;
}

.col-1 {
  grid-column: 1/2;
  grid-row: 1/2;
}

.col-3 {
  grid-column: 1/3;
  grid-row: 2/3;
}

.col-4 {
  grid-column: 1/3;
  grid-row: 3/3;
}

.wider-picker .ant-picker {
  min-width: 100%;
}

.form-grid-container {
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: repeat(2, 1fr);
}

.grid-input {
  grid-column: 1/-1;
}

.sub-title {
  margin-top: 15px;
  display: block;
}

.surprise-select {
  width: 100%;
}

.control-container {
  display: flex;
  justify-content: space-between;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-form-item {
  margin-left: 2px;
  margin-right: 2px;
}

.forbiding-mails-checkbox {
  margin-top: 32px;
}

.input-margin .ant-form-item-control-input {
  margin-right: 3px;
}

@media screen and (max-width: 800px) {
  .content-form-columns {
    grid-template-columns: 1fr;
    padding: 0px;
  }
  .content-form-columns .ant-form-item {
    margin: auto;
  }
  .content-form-3-columns {
    grid-template-columns: 1fr;
    padding: 0px;
    overflow-x: auto;
  }
  .content-form-3-columns .ant-form-item {
    margin: auto;
  }
  .ant-form-item .ant-form-item-label {
    padding: 10px 0 0 0;
  }
  .hide-on-mobile {
    display: none;
  }
  .footer-bottom {
    padding-right: 0px;
  }
  .col-1 {
    grid-column: auto;
    grid-row: auto;
    overflow-x: auto;
  }
  .col-3 {
    grid-column: auto;
    grid-row: auto;
    overflow-x: auto;
  }
  .col-4 {
    grid-column: auto;
    grid-row: auto;
    overflow-x: auto;
  }
  .ant-drawer-content-wrapper {
    max-width: 95% !important;
  }
}
.display-line-text-format dd {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.drawer-buttons {
  display: flex;
  justify-content: flex-end;
}
.drawer-buttons button {
  margin-left: 5px;
}

.grid-config {
  margin: 0 5px;
}

.lower-textarea {
  margin-top: 20px;
}

.separate-buttons {
  display: flex;
  gap: 10px;
}

.delete-icon {
  color: #f63263;
}

.clear-icon {
  position: absolute;
  top: 15px;
  right: 10px;
}

.search-icon {
  font-size: 14px;
  color: #000000;
}

.icons i {
  vertical-align: 0.05em;
}

.nz-list .-item:hover,
.nz-list .nz-list-item.nz-list-item-focus {
  background: rgba(0, 0, 0, 0.05);
}

.small-list * {
  font-size: 10px !important;
}

.status-list {
  letter-spacing: 0.6px;
  padding: 1px 10px;
  border-radius: 10px;
  background: #c4c5d6;
  font-size: 0.8rem;
  line-height: 20px;
  min-height: 20px;
  min-width: 20px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
}

.status-list.info {
  background-color: #36a3f7;
  color: #ffffff;
}

.status-list.success {
  background-color: #009673;
  color: #ffffff;
}

.status-list.error {
  background-color: #f4516c;
  color: #ffffff;
}

.status-list.yellow {
  background-color: #fdd302;
  color: #ffffff;
}

.ant-form,
.ant-form input[type=search],
.ant-form-item,
.ant-input-number,
.ant-list,
.ant-mention-wrapper,
.ant-menu {
  word-break: break-word;
}

.dashboard-departments-header {
  padding: 24px 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wider-select .ant-select.ant-select-in-form-item {
  min-width: 250px;
}

.button-search {
  display: block;
  margin: 25px;
}

.data-input {
  width: 125%;
}

.data-input-parent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 75px;
  align-items: end;
}

.filter-form-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
}

.sum {
  color: #bb3234;
  font-size: 12px;
}

.no-actions-column {
  width: 3%;
}

.case-description p {
  margin: 0;
}

.delayed-substatus {
  background-color: #f8cbad !important;
}

bpc-nz-loader {
  width: 100%;
  height: auto;
}

.local-loader {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 200px;
  margin: auto;
}
.local-loader .spin {
  transform: translate(-50%, -50%);
}

.main-loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.main-loader .spin {
  transform: translate(-50%, -50%);
}

.spin {
  position: absolute;
  left: 50%;
  top: 50%;
  font-weight: bold;
}

.drawer-loader {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.drawer-spin {
  position: absolute;
  left: 40%;
  top: 50%;
  font-weight: bold;
}

.details-spin {
  position: absolute;
  left: 31%;
  top: 50%;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

content {
  padding: 0;
  width: 100%;
  background: transparent;
  overflow-x: auto;
}

.clearfix::before,
.clearfix::after {
  flex-basis: 0;
  order: 1;
}

.header {
  color: #ffffff;
  padding: 0 initial;
  margin: 0 initial;
}

.pointer {
  cursor: pointer;
}

.header-link {
  margin-right: 30px !important;
}
.header-link > * {
  color: #ffffff !important;
}

.pa-to-0 {
  padding-top: 0;
}

.my-content {
  z-index: 10;
}

.ngx-datatable.material {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.no-cursor-pointer *:not(button),
.no-cursor-pointer *:not(input[type=checkbox]) {
  cursor: default !important;
}

.gradient-box {
  height: 195px;
  width: 100%;
  background: linear-gradient(0deg, #234666 0%, #1f2c4f 100%) !important;
  position: absolute;
  z-index: -1;
  top: 56px;
  left: 0;
}

bpc-pages {
  width: 100%;
}

.number-input {
  width: 100%;
}

.data-picker-full-width nz-date-picker {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .ant-page-header-heading-left {
    flex-wrap: wrap;
  }
  .ant-page-header-heading-extra {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}
@media screen and (min-width: 801px) {
  .filter-container {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .filter-container button {
    margin-left: 10px;
    margin-top: 10px;
  }
  .multiple-rows {
    margin-top: -8px;
  }
  .filter-input-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 20px;
  }
  .filter-input-container .last-filter-select {
    margin-top: -30px;
  }
  .filter-action-container {
    padding-top: 22px;
    margin-left: auto;
  }
}
@media screen and (max-width: 801px) {
  .filter-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .filter-container button {
    margin-left: 10px;
    margin-top: 10px;
  }
  .filter-input-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .filter-input-container .ant-form-item-control-input-content {
    margin-bottom: 15px;
  }
  .filter-input-container .ant-form-item {
    margin-bottom: 0px;
  }
  .filter-action-container {
    padding-top: 22px;
  }
}
.editor {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.editor .NgxEditor__MenuBar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.editor .NgxEditor {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: none;
  min-height: 200px;
}
.editor .CodeMirror {
  border: 1px solid #eee;
  height: auto;
  margin-bottom: 0.7rem;
}
.editor .CodeMirror pre {
  white-space: pre !important;
}

.simple-notification .sn-title {
  font-size: 17px !important;
}

.simple-notification .sn-content {
  font-size: 13px !important;
}

.toast-container .toast {
  padding: 20px 15px 20px 50px !important;
}

.toast-success {
  background-color: #6ed16d !important;
}

.toast-error {
  background-color: #bb3234 !important;
}

.toast-warning {
  background-color: #e0d31c !important;
}

.statistic-card {
  width: 100%;
  display: grid;
  gap: 10px;
  margin-top: 35px;
  padding-bottom: 10px;
  grid-template-columns: repeat(4, 1fr);
}
.statistic-card .card {
  position: relative;
  min-width: 15%;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 #bfbfbf;
  padding: 25px 25px 10px 25px;
  width: 100%;
}
.statistic-card .card .card-circle {
  width: 65px;
  height: 65px;
  overflow: hidden;
  background-color: #1f2c4f;
  opacity: 1;
  background-image: repeating-radial-gradient(circle at 100% 0, transparent 0, #1f2c4f 50px), linear-gradient(0deg, #234666 0%, #1f2c4f 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  margin: auto;
  margin-top: -60px;
}
.statistic-card .card .card-circle .card-border {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: -webkit-transform 1s;
}
.statistic-card .card .card-circle .card-border:hover {
  -webkit-transform: rotate(360deg) translateZ(0);
}

.statistic-icon {
  color: #ffffff;
  font-size: 25px;
}

.statistic-value {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  margin-top: 10px;
}

.statistic-name {
  color: #6a6a6a;
  text-align: center;
  margin: 0;
}

:host ::ng-deep .site-page-header.edit-page {
  padding: 16px 0;
}

.ant-page-header {
  padding-bottom: 0px;
}

.example-full-width {
  width: 100%;
}

.matl-data-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
.matl-data-table th,
.matl-data-table td {
  text-align: left;
}
.matl-data-table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.matl-data-table th span {
  display: block;
  padding: 10px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.matl-data-table td {
  padding: 10px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.matl-data-table td .mat-mini-fab {
  width: 30px;
  height: 30px;
}

.matl-data-table tr:last-of-type td {
  border-bottom: 0;
}
.matl-data-table td {
  cursor: pointer;
}
.matl-data-table td:focus {
  outline: none;
}

.matl-data-table thead th,
.matl-data-table thead td {
  border-top: 0 !important;
}
.matl-data-table thead tr:nth-of-type(2) td {
  padding: 0 16px !important;
}
.matl-data-table thead tr:nth-of-type(2) td:nth-of-type(1) {
  padding: 0 16px 0 24px !important;
}

.layout-margin-top {
  margin-top: 16px;
}

.table-wrapper {
  overflow-x: auto;
}

table {
  width: 100%;
}
table tbody tr:nth-child(even) {
  background: #fafafa;
}

.main-list table {
  width: 100%;
}
.main-list table th span {
  display: block;
}

th input {
  width: 60px !important;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-right: 1px solid #ccc;
  text-align: center;
}

.ant-table-thead > tr > th .activeFilter {
  color: #189ce9;
}

.ant-table-thead > tr > th.is-reset-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on {
  color: #bfbfbf;
}

.short-column-header {
  width: 15%;
}

.mid-column-header {
  width: 20%;
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.ant-table-column-sorter-full {
  margin-top: -0.6em;
  margin-bottom: 0;
}

.ant-table-column-sorters {
  padding: 0;
}

.data-row {
  cursor: pointer;
}

nz-table td.capi {
  text-transform: capitalize;
}

.ant-table-tbody > tr > td .ant-tag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;
}

.action-column {
  max-width: 50px;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  width: 50%;
}

.small-action-column {
  width: 30px;
}

.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
  margin-bottom: 5px;
}

.mobile-tabs-open .ant-tabs-nav {
  width: 30px;
  max-width: 30px;
  position: sticky !important;
  top: 0;
  z-index: 9;
  background-color: white;
  transform: translateX(-18px);
}
.mobile-tabs-open .ant-tabs-nav .ant-tabs-tab {
  padding-left: 20px;
}

.ant-tabs {
  margin-right: 0px !important;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding: 0px !important;
}

@media screen and (max-width: 800px) {
  .mobile-tabset {
    max-width: 100dvw;
  }
  .mobile-tabset .ant-tabs-nav {
    height: fit-content;
    max-height: 40%;
    position: fixed;
    right: 10px;
    bottom: 30%;
    z-index: 9;
    background-color: white;
  }
  .mobile-tabset .ant-tabs-tab-active {
    background-color: #1f2c4f !important;
    color: white !important;
  }
  .mobile-tabset .ant-tabs-tab-active button[role=tab] {
    color: white !important;
  }
  .mobile-tabs-collapsed .ant-tabs-nav {
    width: 50px;
    min-width: 50px;
  }
  .mobile-tabs-collapsed .ant-tabs-nav .ant-tabs-tab i {
    font-size: large;
    line-height: normal;
    transform: translateX(-7px);
  }
  .mobile-tabs-button {
    width: 50px;
    position: fixed;
    transform: translateY(100%);
    right: 10px;
    bottom: 30%;
    z-index: 9;
  }
  .mobile-tabs-hide-button {
    width: 50px;
    position: fixed;
    transform: translateY(200%);
    right: 10px;
    z-index: 9;
  }
  .mobile-tabset-open {
    background-color: #1f2c4f;
    color: white;
    border-radius: 5px 0 0 5px;
    width: 25px;
    height: 50px;
    display: grid;
    place-content: center;
    position: fixed;
    right: 60px;
    bottom: 40%;
    z-index: 6;
  }
  .nav-hidden .ant-tabs-nav {
    display: none;
  }
  .tabset-hidden {
    right: 0;
  }
}
.dashboard-photo {
  height: 120px;
  margin-left: 10px;
}

.carousel-content .flex-container .expert-info {
  color: #ffffff;
}
.carousel-content .flex-container .expert-info .expert-name {
  font-weight: bold;
}

.experts-info {
  height: 220px;
  display: grid;
  padding: 20px 20px 0 20px;
  border-radius: 20px;
  background: linear-gradient(0deg, #234666 0%, #1f2c4f 100%);
  box-shadow: 0 0 6px 0 #bfbfbf;
}
.experts-info .experts-header p {
  font-weight: bold;
  color: #ffffff;
  font-size: 20px;
  margin-bottom: -50px;
}
.experts-info p {
  margin: 0;
  margin-top: 0;
}

.flex-container .expert-info {
  color: #ffffff;
}
.flex-container .expert-info .expert-name {
  font-weight: bold;
}

.grid-span {
  padding-right: 3px;
}

.drawer-buttons {
  display: flex;
  justify-content: flex-end;
}
.drawer-buttons button {
  margin-left: 5px;
}

.details {
  position: relative;
  background: #1f2c4f;
  background: linear-gradient(0deg, #234666 0%, #1f2c4f 100%);
  box-sizing: border-box;
  color: #ffffff;
  overflow: auto;
  height: 100%;
  padding: 0px 16px 32px 16px;
  margin-top: 0.33px;
}

.grid {
  display: grid;
  grid-gap: 15px 5px;
  grid-template-columns: 50% 50%;
  padding: 30px 0 0 0;
  text-align: left;
}
.grid strong {
  grid-column: 1/3;
}
.grid-full {
  grid-template-columns: 100%;
}
.grid dl {
  font-size: 14px;
  margin: 0;
}

.infobar-divider {
  background-color: #ffffff;
}

.info-bar-label-looks {
  color: #ccc;
}

.edit-button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 10px 0 10px;
}

@media screen and (max-width: 800px) {
  .data-table {
    display: none;
  }
  .data-card {
    margin: 10px;
    border-radius: 15px;
  }
}
@media screen and (min-width: 801px) {
  .data-card {
    display: none;
  }
}
.actionColumn {
  width: 100px;
}

.timeline-details {
  width: 600px;
}

.long-column-header {
  width: 35%;
}

.add-penalty-interest {
  margin: 10px 0;
}

.datepicker {
  margin-right: 10px;
  margin-left: 10px;
}

.policyLink {
  position: absolute;
  right: 10px;
}

.timeline-image {
  width: 100%;
}

.timeline-container {
  padding-top: 30px;
}

.direction-l {
  position: relative;
  width: 350px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 350px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(248, 248, 248);
  padding: 6px 10px;
  border-radius: 5px;
  font-weight: 600;
  text-align: left;
}

.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -36px;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  margin-top: -10px;
  background: #fff;
  border-radius: 10px;
  border: 4px solid rgb(255, 80, 80);
  z-index: 10;
}

.direction-r .flag:before {
  left: -36px;
}

.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: rgb(248, 248, 248);
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: rgb(248, 248, 248);
  border-width: 8px;
  pointer-events: none;
}

.time-wrapper {
  display: inline;
  line-height: 1em;
  font-size: 0.66666em;
  color: rgb(250, 80, 80);
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248, 248, 248);
}

.desc {
  margin: 1em 0.75em 0 0;
  font-size: 0.77777em;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}

.wide-column {
  width: 20%;
}

/* ================ Timeline Media Queries ================ */
@media screen and (max-width: 660px) {
  .timeline {
    width: 100%;
    padding: 4em 0 1em 0;
  }
  .timeline li {
    padding: 2em 0;
  }
  .direction-l,
  .direction-r {
    float: none;
    width: 100%;
    text-align: center;
  }
  .flag-wrapper {
    text-align: center;
  }
  .flag {
    background: rgb(255, 255, 255);
    z-index: 15;
  }
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
    left: 50%;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin-left: -9px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid rgb(255, 80, 80);
    z-index: 10;
  }
  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -8px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: rgb(255, 255, 255);
    border-width: 8px;
    pointer-events: none;
  }
  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }
  .direction-l .time-wrapper {
    float: none;
  }
  .direction-r .time-wrapper {
    float: none;
  }
  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    background: rgb(245, 245, 245);
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    z-index: 15;
  }
  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;
    z-index: 15;
  }
}
.sub-status-font {
  font-weight: bold;
}

.ck-editor__editable_inline {
  min-height: 300px;
}