@import 'variable';

.details {
  position: relative;
  background: $blue-dark;
  background: $gradient-background;
  box-sizing: border-box;
  color: $white;
  overflow: auto;
  height: 100%;
  padding: 0px 16px 32px 16px;
  margin-top: 0.33px;
}

.grid {
  display: grid;
  grid-gap: 15px 5px;
  grid-template-columns: 50% 50%;
  padding: 30px 0 0 0;
  text-align: left;

  strong {
    grid-column: 1/3;
  }
  &-full {
    grid-template-columns: 100%;
  }
  dl {
    font-size: 14px;
    margin: 0;
  }
}

.infobar-divider {
  background-color: $white
}

.info-bar-label-looks {
  color: $gray-lighter;
}

.edit-button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 10px 0 10px;
}
