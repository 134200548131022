@use '@angular/material' as mat;

$primary: mat.define-palette(mat.$grey-palette, 500, 500, 500);
$accent: mat.define-palette(mat.$teal-palette, 500, 300, 700);
$warn: mat.define-palette(mat.$red-palette, 900, 900, 900);

$background-color: #f5f5f5;
$black: #000000;
$black-lighter: #525252;
$blue-background: #189ce9;
$border-color: #0000001f;
$box-shadow-background: #bfbfbf;
$card-background: #fafafa;
$gray: #6a6a6a;
$gray-border: #f0f0f0;
$gray-dark: #666;
$gray-darker: #525252;
$gray-lighter: #ccc;
$ind-invest-background: #fff2f0;
$light-dark-gradient-background: linear-gradient(0deg, #1f2c4f 0%, #234666 100%);
$light-purple: #7365ff;
$light-red: #f63263;
$link-blue: #0da0fe;
$list-background: rgba(0, 0, 0, 0.05);
$pink-background: #ec63b3;
$status-white: #c4c5d6;
$status-blue: #36a3f7;
$status-green: #009673;
$status-red: #f4516c;
$status-yellow: #fdd302;
$transparent-background: rgba(0, 0, 0, 0.3);
$white: #ffffff;
$yellow-background: #e0d31c;
$closed-blue: #8de9fe;
$status-new: #abfaaf;
$rate-delayed-column-background-color: #f8cbad;
$green-background: #6ed16d;
$light-green-background: #c2ecc0;
$light-blue-background: #a7b5dd;
$red-background: #bb3234;
$blue-dark: #1f2c4f;
$gradient-background: linear-gradient(0deg, #234666 0%, #1f2c4f 100%);
$main-app-color: #1f2c4f;
$disabled-button-blue-background: #36a3f7;
$dark-text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
$dark-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
$responsive_min-width: 360px;
$responsive_mobile: 640px;
$responsive_tablet: 1024px;
$minComputerWidth: 801px;
$maxMobileWidth: 800px;
$delayed-substatus: #ffa500;
$warning-colour: #ffa500;
