@import 'variable';

.delete-icon {
color: $light-red;
}

.clear-icon {
  position: absolute;
  top: 15px;
  right: 10px;
}

.search-icon {
  font-size: 14px;
  color: $black;
}

.icons i {
  vertical-align: 0.05em;
}