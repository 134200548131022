@import 'variable';
.ant-card {
  border-radius: 20px;
  margin: 16px 0;
}

.edit-card {
  margin: 0;
}

.nz-card-no-margins {
  margin: 0;
}

.edit-card-w-margin-bottom {
  margin-bottom: 16px;
}

.ant-card-head {
  display: flex;
  align-items: center;
  min-height: 80px;
  color: $white;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: repeating-radial-gradient(circle at 100% 0, transparent 0, $blue-dark 50px), $gradient-background;
  &-title {
    white-space: pre-line;
  }
}

.ant-card-head-wrapper {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}

.margin {
  margin: 0 10px;
  ant-row {
    flex-flow:none !important
  }
  nz-card {
    width: 80%;
    flex-flow:none !important
  }
}

.card {
  &-blue {
    background: $light-blue-background;
    .nz-list-text {
      color: $white;
    }
  }
  &-darkblue {
    background: $blue-dark;
    .nz-list-text {
      color: $white;
    }
    td {
      font-size: 13px;
    }
  }
  &-green {
    background: $light-green-background;
    .nz-list-text {
      color: $white;
    }
    td {
      font-size: 13px;
    }
  }
  &-yellow {
    background: $yellow-background;
    .nz-list-text {
      color: $white;
    }
    td {
      font-size: 13px;
    }
  }
}

.add-image-container {
  min-height: 300px;
}

.file-input {
  display: none;
}

.button-container {
  margin-top: 16px;
}

.photo-file {
  max-width: 200px;
  max-height: 200px;
}

.structure-card {
  margin-right: 16px;
}
