.footer-bottom {
  width: 100%;
  display: flex;
  position: sticky;
  padding: 3px;
  bottom: 0;
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  justify-content: flex-end;
  padding-right: 20px;
  z-index: 100;
  background: white;
}

.formContent {
  display: contents;
}

.content-form-columns {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding: 0px 20px 20px;
}

.content-form-3-columns {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  padding: 0px 20px;
}

.col-1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.col-3 {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.col-4 {
  grid-column: 1 / 3;
  grid-row: 3 / 3;
}

.wider-picker {
  .ant-picker {
    min-width: 100%;
  }
}

.form-grid-container {
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: repeat(2, 1fr);
}

.grid-input {
  grid-column: 1 / -1;
}

.sub-title {
  margin-top: 15px;
  display: block;
}

.surprise-select {
  width: 100%;
}

.control-container {
  display: flex;
  justify-content: space-between;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-form-item {
  margin-left: 2px;
  margin-right: 2px;
}

.forbiding-mails-checkbox {
  margin-top: 32px;
}

.input-margin {
  .ant-form-item-control-input {
    margin-right: 3px;
  }
}

@media screen and (max-width: 800px) {
  .content-form-columns {
    grid-template-columns: 1fr;
    padding: 0px;
    .ant-form-item {
      margin: auto;
    }
  }

  .content-form-3-columns {
    grid-template-columns: 1fr;
    padding: 0px;
    overflow-x: auto;
    .ant-form-item {
      margin: auto;
    }
  }

  .hide-on-mobile {
    display: none;
  }

  .footer-bottom {
    padding-right: 0px;
  }

  .col-1 {
    grid-column: auto;
    grid-row: auto;
    overflow-x: auto;
  }

  .col-3 {
    grid-column: auto;
    grid-row: auto;
    overflow-x: auto;
  }

  .col-4 {
    grid-column: auto;
    grid-row: auto;
    overflow-x: auto;
  }

  .ant-drawer-content-wrapper {
    max-width: 95% !important;
  }
}

.display-line-text-format {
  dd {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.drawer-buttons {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 5px;
  }
}

.grid-config {
  margin: 0 5px;
}

.lower-textarea {
  margin-top: 20px;
}

.separate-buttons {
  display: flex;
  gap: 10px;
}
