@import 'variable';

.example-full-width {
  width: 100%;
}

.matl-data-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  th,
  td {
    text-align: left;
  }
  th {
    border-bottom: 1px solid $list-background;
    span {
      display: block;
      padding: 10px;
      color: $transparent-background;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  td {
    padding: 10px !important;
    border-top: 1px solid $list-background;

    .mat-mini-fab {
      width: 30px;
      height: 30px;
    }
  }
}

.matl-data-table {
  tr:last-of-type {
    td {
      border-bottom: 0;
    }
  }
  td {
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}

.matl-data-table {
  thead {
    th,
    td {
      border-top: 0 !important;
    }
    tr:nth-of-type(2) {
      td {
        padding: 0 16px !important;
        &:nth-of-type(1) {
          padding: 0 16px 0 24px !important;
        }
      }
    }
  }
}

.layout-margin {
  &-top {
    margin-top: 16px;
  }
}

.table-wrapper {
  overflow-x: auto;
}

table {
  width: 100%;
  tbody {
    tr:nth-child(even) {
      background: $card-background;
    }
  }
}

.main-list table {
  width: 100%;

  th {
    span {
      display: block;
    }
  }
}

th input {
  width: 60px !important;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border-right: 1px solid $gray-lighter;
  text-align: center;
}

.ant-table-thead > tr > th .activeFilter {
  color: $blue-background;
}

.ant-table-thead
  > tr
  > th.is-reset-sort
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on {
  color: $box-shadow-background;
}

.short-column-header {
  width: 15%;
}

.mid-column-header {
  width: 20%;
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.ant-table-column-sorter-full {
  margin-top: -0.6em;
  margin-bottom: 0;
}

.ant-table-column-sorters {
  padding: 0;
}

.data-row {
  cursor: pointer;
}

nz-table td.capi {
  text-transform: capitalize;
}

.ant-table-tbody > tr > td {
  .ant-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 100%;
  }
}

.action-column {
  max-width: 50px;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  width: 50%
}

.small-action-column {
  width: 30px;
}

.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
  margin-bottom: 5px;
}
