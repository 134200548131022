.document-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h3 {
    font-size: 16px;
    font-weight: 600;
    white-space: initial !important;
    margin-bottom: 0;
    line-height: 1.5;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__add {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &__download {
    a {
      align-items: center;
    }
  }

  &:hover {
    .document-item__add {
      opacity: 1;
    }
  }
}

.document_add_for_transfer {
  width: 400px;
}

.insurance-download {
  height: 38px !important;
  width: 120px;
  font-size: 15px !important;
}

.document-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

@media screen and (min-width: $minComputerWidth) {
  .document-item {
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    text-align: auto;
    align-items: auto;
  }
}
