.grid-span {
  padding-right: 3px;
}

.drawer-buttons {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 5px;
  }
}
