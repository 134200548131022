@import 'variable';

.dashboard-photo {
  height: 120px;
  margin-left: 10px;
}


.carousel-content {
  .flex-container {
    .expert-info {
      color: $white;
      .expert-name {
        font-weight: bold;
      }
    }
  }
}
.experts-info {
  height: 220px;
  display: grid;
  padding: 20px 20px 0 20px;
  border-radius: 20px;
  background: $gradient-background;
  box-shadow: 0 0 6px 0 $box-shadow-background;
  .experts-header {
    p {
      font-weight: bold;
      color: $white;
      font-size: 20px;
      margin-bottom: -50px;
    }
  }
  p {
    margin: 0;
    margin-top: 0;
  }
}

.flex-container {
  .expert-info {
      color: $white;
      .expert-name {
        font-weight: bold;
      }
    }
}
