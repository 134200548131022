@import 'variable';

.nz-list .-item:hover,
.nz-list .nz-list-item.nz-list-item-focus {
  background: $list-background;
}

.small-list {
  * {
    font-size: 10px !important;
  }
}

.status-list {
  letter-spacing: 0.6px;
  padding: 1px 10px;
  border-radius: 10px;
  background: $status-white;
  font-size: 0.8rem;
  line-height: 20px;
  min-height: 20px;
  min-width: 20px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
}
.status-list.info {
  background-color: $status-blue;
  color: $white;
}
.status-list.success {
  background-color: $status-green;
  color: $white;
}
.status-list.error {
  background-color: $status-red;
  color: $white;
}
.status-list.yellow {
  background-color: $status-yellow;
  color: $white;
}

.ant-form,
.ant-form input[type='search'],
.ant-form-item,
.ant-input-number,
.ant-list,
.ant-mention-wrapper,
.ant-menu {
  word-break: break-word;
}

.dashboard-departments-header {
  padding: 24px 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wider-select {
  .ant-select.ant-select-in-form-item {
    min-width: 250px;
  }
}

.button-search {
  display: block;
  margin: 25px;
}

.data-input {
  width: 125%;
}

.data-input-parent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 75px;
  align-items: end;
}

.filter-form-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  border: 1px solid $gray-border;
  border-radius: 20px;
}

.sum {
  color: $red-background;
  font-size: 12px;
}

.no-actions-column {
  width: 3%
}

.case-description {
  p {
    margin: 0;
  }
}

.delayed-substatus {
  background-color: $rate-delayed-column-background-color !important;
}
