.statistic-card{
  width: 100%;
  display: grid;
  gap: 10px;
  margin-top: 35px;
  padding-bottom: 10px;
  grid-template-columns: repeat(4, 1fr);
  .card{
    position: relative;
    min-width: 15%;
    border-radius: 20px;
    box-shadow: 0 0 6px 0 $box-shadow-background;
    padding: 25px 25px 10px 25px;
    width: 100%;
    .card-circle{
      width: 65px;
      height: 65px;
      overflow: hidden;
      background-color: $blue-dark;
      opacity: 1;
      background-image: repeating-radial-gradient(circle at 100% 0, transparent 0, $blue-dark 50px), $gradient-background;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 10px;
      margin: auto;
      margin-top: -60px;
      .card-border{
        border: 2px solid #fff;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-transition: -webkit-transform 1s;
      }
      .card-border:hover{
        -webkit-transform: rotate(360deg) translateZ(0);
      }
    }
  }
}

.statistic-icon{
  color: $white;
  font-size: 25px
}

.statistic-value {
  color: $black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  margin-top: 10px;
}

.statistic-name{
  color: $gray;
  text-align: center;
  margin: 0;
}
