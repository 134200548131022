.bold {
  font-weight: bold;
}

content {
  padding: 0;
  width: 100%;
  background: transparent;
  overflow-x: auto;
}

.clearfix::before,
.clearfix::after {
  flex-basis: 0;
  order: 1;
}

.header {
  color: $white;
  padding: 0 initial;
  margin: 0 initial;
}
.pointer {
  cursor: pointer;
}

.header-link {
  margin-right: 30px !important;
  & > * {
    color: $white !important;
  }
}

.pa-to-0 {
  padding-top: 0;
}

.my-content {
  z-index: 10;
}

.ngx-datatable.material {
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.no-cursor-pointer *:not(button),
.no-cursor-pointer *:not(input[type='checkbox']) {
  cursor: default !important;
}

.gradient-box {
  height: 195px;
  width: 100%;
  background: $gradient-background !important;
  position: absolute;
  z-index: -1;
  top: 56px;
  left: 0;
}

bpc-pages {
  width: 100%;
}

.number-input {
  width: 100%;
}

.data-picker-full-width {
  nz-date-picker {
    width: 100%;
  }
}

@media screen and (max-width: $maxMobileWidth) {
  .ant-page-header-heading-left {
    flex-wrap: wrap;
  }

  .ant-page-header-heading-extra {
    display: flex;
    flex-wrap: wrap;
  }
}
