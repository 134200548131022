bpc-nz-loader {
  width: 100%;
  height:auto
}
.local-loader {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 200px;
  margin: auto;
  .spin {
      transform: translate(-50%, -50%);
  }
}

.main-loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  .spin {
      transform: translate(-50%, -50%);
  }
}

.spin {
  position: absolute;
  left: 50%;
  top: 50%;
  font-weight: bold;
}

.drawer-loader {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $white;
}

.drawer-spin {
  position: absolute;
  left: 40%;
  top: 50%;
  font-weight: bold;
}

.details-spin {
  position: absolute;
  left: 31%;
  top: 50%;
  font-weight: bold;
}
