.payments-container {
  margin-top: 40px;
  height: auto;
}

.input-container {
  margin-left: 10px;
}

.search-box {
  padding: 10px;
  background-color: $white;
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.multi-select {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.page-container {
  padding: 1px 16px;
}

.document-item__add {
  display: flex;
  padding: 0px 15px;
}
