$ngx-editor-border-color:#00000033;
.editor {
  border: 2px solid $ngx-editor-border-color;
  border-radius: 4px;

  .NgxEditor__MenuBar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid $ngx-editor-border-color;
  }

  .NgxEditor {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    border: none;
    min-height: 200px;
  }

  .CodeMirror {
    border: 1px solid #eee;
    height: auto;
    margin-bottom: 0.7rem;

    pre {
      white-space: pre !important;
    }
  }
}
