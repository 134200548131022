@import 'variable';

.ant-input[disabled] {
  color: $black-lighter;
}

.ant-input[disabled]::placeholder {
  color: $black-lighter;
}

.ant-picker-input > input[disabled] {
  color: $black-lighter;
}

.ant-picker-input > input[disabled]::placeholder {
  color: $black-lighter;
}

.ant-input-number-disabled .ant-input-number-input {
  color: $black-lighter;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: $black-lighter;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selection-placeholder {
  color: $black-lighter;
}

.ant-checkbox-disabled + span {
  color: $black-lighter;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: $black-lighter;
}

@media screen and (min-width: $minComputerWidth) {
  .small-screen-only {
    display: none;
  }
}