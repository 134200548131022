@import 'variable';

.file {
  &-container {
    padding: 8px;
    position: relative;
    margin-bottom: 15px;

    &:first-child {
      padding-left: 0;
    }
  }

  &-thumbnail {
    background-size: cover;
    background-position: center center;
    height: 200px;
    width: 100%;
    border-radius: 5px;
    position: relative;

    a {
      display: block;
      width: 100%;
      opacity: 0;
      background: $transparent-background;
      height: 110px;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      padding-top: 90px;
      font-size: 16px;
      font-weight: 800;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }

  &-date {
    color: $gray-lighter;
    float: right;
    font-size: 9px;
    display: block;
  }

  &-delete {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: $transparent-background;
  }
}

.incoming-post.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  background-color: $green-background;
  border-color: $green-background;
}

.post-label-buttons.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $status-blue;
  border-color: $status-blue;
}
